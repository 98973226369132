/*
 * APP 
 */
.app {
  display: flex;
  flex-direction: row;
}

/*
 * NAV
 */
.nav__container {
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 0;
  display: block;
  width: 100%;
  list-style: none;
}

.nav {
  text-align: center;
}

.nav__btn {
  margin: 2px 2px 2px 0;
  padding: 6px;
  font-size: 14px;
  color: #fff;
  background: #B91372;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  text-decoration: none;
}

/*
 * HERO
 */
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  height: 100vh;
  max-width: 400px;
  background: #F2F5F3;
  text-align: center;
}

.hero__header {
  color: #4a4a4a;
}

.hero__profile {
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
  width: 200px;
  margin-bottom: 30px;
  border: 8px solid #181D29;
}

.hero__subheader {
  font-weight: 300;
}

.hero__text {
  margin: 10px 0;
  font-weight: 700;

}

.hero__logo {
  height: 40px;
  vertical-align: middle;
  animation: hero-logo infinite 20s linear;
}

@keyframes hero-logo {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hero__arrow {
  display: inline-block;
  position: absolute;
  left: calc(50% - 20px);
  bottom: 20px;
  font-size: 40px;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: hero-arrow;
}

@keyframes hero-arrow {
  from {
    opacity: 0.25;
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 1;
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    opacity: 0.25;
    transform: scale3d(1, 1, 1);
  }
}

/*
 * CARD
 */
.card__content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/*
 * JOB
 */
.job__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 50px;
  color: #F2F5F3;
  background: #54e7c7;
}

.job__date {
  font-size: 14px;
  font-weight: 300;
}

.job__paragraph {
  font-style: italic;
}

.job a {
  color: white;
  font-style: italic;
}

.job__header {
  margin-top: 10px;
}

.job__btn {

  padding: 2px;
  font-size: 14px;
  color: #fff;
  background: #B91372;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  text-decoration: none;
}


.project-container{
  display: flex;
  flex-direction: column;
  gap: 20px;


}
.project-card {
 display: flex;
 flex-direction: column;
 gap: 5px;
 padding: 10px;

}

.project-card__header {
  display: flex;
gap: 10px;
  color: #4a4a4a;
  font-size: large;
  font-weight: 700;
}

/*
 * SKILLS
 */
.skill {
  margin: 2px 4px 2px 0;
  color: #fff;
  padding: 8px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  background: #B91372;
}

.skills-list__header {
  color: #4a4a4a;
  text-align: center;
}

.skills-list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
}

/*
 * EDUCATION
 */
.education__header {
  color: #4a4a4a;
  text-align: center;

}

.education__text {
  text-align: center;
  margin-bottom: 20px;
}

.education__subheader {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: larger;
}

.education__btn {

  padding: 2px;
  margin-bottom: 10px;
  font-size: 16px;
  /* 100 = viewport width, as 1vw = 1/100th of that
       So if the container is 50% of viewport (as here)
       then factor that into how you want it to size.
       Let's say you like 5vw if it were the whole width,
       then for this container, size it at 2.5vw (5 * .5 [i.e. 50%])
    */

  color: #fff;
  background: #B91372;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  text-decoration: none;

}

/*
 * CONTACT
 */
.contact__header {
  color: #4a4a4a;
}

/*
 * CONTAINERS
 *
 * card
 * skills-list
 * contact
 * education
 */
.card__container {
  width: 60%;
}

.skills-list__container,
.contact__container,
.education__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*
 * HEADERS
 *
 * hero
 * skills-list
 * education
 * contact
 */
.hero__header,
.contact__header,
.education__header,
.skills-list__header {
  margin-bottom: 15px;
}
.hero__header {
  margin-bottom: 5px;
}

@media (max-width: 370px) {
  .nav__container {
    display: none;
  }

  .hero__arrow {
    display: none;
  }
}

@media (max-width: 990px) {

  /*
   * APP 
   */
  .app {
    flex-direction: column;
  }

  /*
   * CONTAINERS
   *
   * card
   * skills-list
   * education 
   * contact 
   */
  .card__container {
    width: 100vw;
  }

  .skills-list__container,
  .education__container,
  .contact__container {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  /*
   * MODALS
   *
   * skills-list
   * education 
   * contact 
   */
  .skills-list__modal,
  .education__modal,
  .contact__modal {
    background-color: #fefefe;
    margin: 0 auto;
    padding: 20px;
    width: calc(100% - 40px);
  }
}

@media (min-width: 991px) {
  .nav {
    position: absolute;
    left: calc(20% - 162px);
    text-align: left;
  }

  .hero {
    width: 40%;
    height: 100vh;
  }

  .hero__arrow {
    display: none;
  }

  .card__container {
    height: 100vh;
    overflow: auto;
  }

  .card__content {
    min-height: 100vh;
    overflow-y: auto;
  }

  /*
   * CONTAINERS
   *
   * skills-list
   * education 
   * contact 
   */
  .contact__container,
  .education__container,
  .skills-list__container {
    width: 60%;
  }
}

@media only screen and (orientation: landscape) {
  .nav__container {
    display: none;
  }

  .hero__arrow {
    display: none;
  }
}

@media only screen and (orientation: landscape) and (min-width: 820px) {
  .nav__container {
    display: block;
  }

  .hero__arrow {
    display: block;
  }
}

@media only screen and (orientation: landscape) and (min-width: 991px) {
  .hero__arrow {
    display: none;
  }
}