@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 200;
  src: local('Muli ExtraLight'), local('Muli-ExtraLight'), url(https://fonts.gstatic.com/s/muli/v10/_CFpa-kZ5cjoOxxYIA5RegLUuEpTyoUstqEm5AMlJo4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: local('Muli Light'), local('Muli-Light'), url(https://fonts.gstatic.com/s/muli/v10/mls_29xz6YyJHsylG8hcCgLUuEpTyoUstqEm5AMlJo4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli Regular'), local('Muli-Regular'), url(https://fonts.gstatic.com/s/muli/v10/zscZFkjVRGyfQ_Pw-5exXPesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: local('Muli Bold'), local('Muli-Bold'), url(https://fonts.gstatic.com/s/muli/v10/4CQLgXecGZ8f_sbf4m6a6gLUuEpTyoUstqEm5AMlJo4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 900;
  src: local('Muli Black'), local('Muli-Black'), url(https://fonts.gstatic.com/s/muli/v10/uJyODlFN0nywM_6RzaKsvgLUuEpTyoUstqEm5AMlJo4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #F2F5F3;
  font-family: 'Muli', sans-serif;
  /* font-family: "Courier New", Courier, monospace; */
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
}
